<template>
  <div class="dashboard-storage">
    <div class="storage-options">

        <h1>{{ $t('storage.storage') }}</h1>
    
        <div class="orders-searchbar">
            <input v-model="search" type="text" :placeholder="$t('storage.search')" />
            <button class="action-light">
              <img src="@/assets/svg/v2/search.svg" alt="Search icon" />
            </button>
        </div>
    </div>

    <Transition name="action-headband-scale">
      <div v-if="bottlesChecked.length > 0" class="action-headband action-light">
        {{ bottlesChecked.length }} {{ $t('portfolio.selected_bottles') }}

        <div>
          <button @click="drawerExitBottleStorage = true">
            <img
              :src="require('@/assets/svg/v2/portfolio/moveButton.svg')"
              :alt="$t('storage.exit_bottles')"
              class="themed-img"
            />
            {{ $t('storage.exit_bottles') }}
          </button>
        </div>
      </div>
    </Transition>

    <div class="storage">
      <el-table :data="visiblestorageFiltered" class="bottles-list orders-list" stripe row-key="ticker" :tree-props="{ hasChildren: 'hasChildren', children: 'children' }">

        <el-table-column :label="''" prop="" min-width="45">
          <template v-slot="{ row }">
            <el-checkbox v-if="!row.children" @change="checkBottle(row)" v-model="row.is_check" label="" size="" />
          </template>
        </el-table-column>

        <el-table-column :label="$t('storage.supplier_name')" prop="pseudo" min-width="70"></el-table-column>

        <el-table-column :label="$t('storage.store_at')" prop="date" width="180">
          <template v-slot="{ row }">
            <a class="title">{{ dateConvert(row.store_start) }}</a>
          </template>
        </el-table-column>

        <el-table-column :label="$t('commons.spirit_type_short')" sortable prop="spirit_type_label" min-width="100"></el-table-column>
        <el-table-column label="Ticker" width="140" sortable prop="ticker"></el-table-column>
        <el-table-column :label="$t('search.name')" sortable prop="title" min-width="250"></el-table-column>
        <el-table-column :label="$t('commons.quantity')" prop="quantity">
          <template v-slot="{ row }">
            <span v-if="row.children">{{ row.children.length }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <!-- <el-table-column label="" prop="actions" width="130">
          <template v-slot="{ row }">
            <div v-if="!row.children" class="buttons-storage-column">
              <button @click="downloadInvoice(row)" class="download-invoice">
                <el-tooltip
                  :content="$t('storage.invoice_download')"
                  class="tooltip"
                  effect="dark"
                  placement="top"
                >
                  <img
                    src="@/assets/svg/v2/settings/invoiceHistoryActive.svg"
                    class="themed-img"
                    alt="Invoice download"
                  />
                </el-tooltip>
              </button>

              <button @click="viewBottle(row)" class="download-invoice">
                <el-tooltip
                  :content="$t('storage.check_edit')"
                  class="tooltip"
                  effect="dark"
                  placement="top"
                >
                  <img alt="Check and edit bottle" src="@/assets/svg/v2/edit.svg" class="themed-img"/>
                </el-tooltip>
              </button>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <el-drawer
      v-model="drawerExitBottleStorage"
      :title="$t('subscriptions.recap_payment')"
      :size="drawerSize"
    >
      <div v-if="getUserDetails" class="contact-billing-storage">
        <div class="contact">
          <h3>{{ $t('subscriptions.contact_informations') }}</h3>
          <p>+{{ getUserDetails.address.phone_country_code }} {{ getUserDetails.address.phone }} - {{ getUserDetails.address.email }}</p>
          <button @click="$router.push('/auth/account/details')">{{ $t('subscriptions.update') }}</button>
        </div>
        <div class="billing">
          <h3>{{ $t('commons.delivery') }}</h3>
          <p>{{ getClientAddress() }}</p>
          <button @click="$router.push('/auth/account/details')">{{ $t('subscriptions.update') }}</button>
        </div>
      </div>

      <div class="bottle-exit-line bottles-list-exit" v-for="(bottle, i) in mergeExitBottles" :key="i">
        <p>{{ bottle?.ticker }} - {{ bottle?.title }} (<strong>x{{ mergeExitBottles.length }}</strong>)</p>
        <span>{{ '-' }}</span>
      </div>

      <div v-if="getRatings" class="bottle-exit-line">
        <p>{{ $t('payment_process.delivery_fees') }} ({{ getRatings.provider }})</p>
        <span>{{ getRatings.shipping_without_vat}} EUR</span>
      </div>

      <div v-if="getRatings?.insurrance_without_vat > 0" class="bottle-exit-line">
        <p>{{ $t('payment_process.inssurance_global') }} ({{ getRatings.provider }})</p>
        <span>{{ insurranceStorageSelected ? getRatings.insurrance_without_vat : 0 }} EUR</span>
      </div>
      <div v-else-if="getRatings?.insurrance_without_vat === 0" class="bottle-exit-line">
        <p>{{ $t('payment_process.inssurance_global_include') }} ({{ getRatings.provider }})</p>
        <span>{{ 0 }} EUR</span>
      </div>

      <div v-if="getRatings?.insurrance_without_vat > 0" class="bottle-exit-line">
        <el-checkbox v-model="insurranceStorageSelected" :label="$t('payment_process.insurrance_option_storage')" size="small" />
      </div>

      <div v-if="getRatings" class="bottle-exit-line">
        <p>{{ $t('payment_process.storage_exit_fees') }}</p>
        <span>{{ getRatings.exit_fees_without_vat }} EUR</span>
      </div>
      
      <div v-if="getRatings" class="bottle-exit-line">
        <p>{{ $t('payment_process.payment_type_fees') }} ({{ paymentFeesPercent }}%)</p>
        <span>{{ paymentTypeFees() }} EUR</span>
      </div>

      <div v-if="getRatings" class="bottle-exit-line">
        <p>{{ $t('subscriptions.buy_commission_tva') }} (20%)</p>
        <span>{{ insurranceStorageSelected ? parseFloat(getRatings.total_vat + getRatings.insurrance_vat).toFixed(2) : getRatings.total_vat }} EUR</span>
      </div>

      <div v-if="getRatings" class="bottle-exit-line">
        <strong>{{ $t('subscriptions.total') }}</strong>
        <strong>{{ getTotalExitPriceStorage() }} EUR</strong>
      </div>

      <el-divider />

      <div class="cart-payment-method-choice">
        <div @click="paymentMode = 'CB_VISA_MASTERCARD'" class="CB_VISA_MASTERCARD">
          <img
            v-if="paymentMode === 'CB_VISA_MASTERCARD'"
            src="@/assets/svg/v2/dotActive.svg"
            alt="Selected visa mastercard payment method"
            class="active-inactive"
          />
          <img
            v-else
            src="@/assets/svg/v2/dot.svg"
            alt="Inactive visa mastercard"
            class="active-inactive themed-current"
          />
          <img src="@/assets/svg/v2/subscriptions/visa.svg" alt="Visa" />
          <img
            src="@/assets/svg/v2/subscriptions/mastercard.svg"
            alt="Mastercard"
          />
        </div>

        <div @click="paymentMode = 'AMEX'" class="AMEX">
          <img
            v-if="paymentMode === 'AMEX'"
            src="@/assets/svg/v2/dotActive.svg"
            alt="Selected AMEX payment method"
            class="active-inactive"
          />
          <img
            v-else
            src="@/assets/svg/v2/dot.svg"
            alt="Inactive AMEX mastercard"
            class="active-inactive themed-current"
          />
          <img src="@/assets/svg/v2/subscriptions/ae.svg" alt="AE" />
        </div>

        <div @click="paymentMode = 'paypal'" class="paypal">
          <img
            v-if="paymentMode === 'paypal'"
            src="@/assets/svg/v2/dotActive.svg"
            alt="Selected paypal payment method"
            class="active-inactive"
          />
          <img
            v-else
            src="@/assets/svg/v2/dot.svg"
            alt="Inactive paypal mastercard"
            class="active-inactive themed-current"
          />
          <img src="@/assets/svg/v2/payment/paypal.svg" alt="Paypal" />
        </div>

        <div @click="paymentMode = 'wallet'" class="wallet">
          <img
            v-if="paymentMode === 'wallet'"
            src="@/assets/svg/v2/dotActive.svg"
            alt="Selected wallet payment method"
            class="active-inactive"
          />
          <img
            v-else
            src="@/assets/svg/v2/dot.svg"
            alt="Inactive wallet"
            class="active-inactive themed-current"
          />
          <img src="@/assets/svg/v2/wallet/wallet.svg" alt="Wallet" class="themed-img"/>
          <p>{{ $t('commons.wallet') }}</p>
        </div>
      </div>

      <form v-if="paymentMode === 'CB_VISA_MASTERCARD' || paymentMode === 'AMEX'" class="form-storage-payment">
        <el-input
          class="form-input"
          :class="{ errorInput: cardRegex.fullnames }"
          :placeholder="$t('subscriptions.card_fullnames')"
          type="text"
          minlength="5"
          maxlength="75"
          v-model="card.fullnames"
          :size="size"
        />
        <el-input
          class="form-input"
          :class="{ errorInput: cardRegex.card_number }"
          :placeholder="$t('subscriptions.card_number')"
          type="text"
          minlength="15"
          maxlength="20"
          v-model="card.card_number"
          :size="size"
          :suffix-icon="Lock"
        />

        <div>
          <el-input
            class="form-input"
            clearable
            :class="{ errorInput: cardRegex.card_expiration_date }"
            :placeholder="$t('subscriptions.card_expiration')"
            type="text"
            minlength="5"
            maxlength="10"
            v-model="card.card_expiration_date"
            :size="size"
          />
          <el-input
            class="form-input"
            :class="{ errorInput: cardRegex.card_cvx }"
            :placeholder="$t('subscriptions.card_code_security')"
            type="text"
            minlength="3"
            maxlength="4"
            v-model="card.card_cvx"
            :size="size"
            :suffix-icon="QuestionFilled"
          />
        </div>
      </form>

      <div class="storage-exit-process-payment">
        <button class="generic-btn">{{ $t('payment_process.storage_exit_payment_disabled') }}</button>
        <!-- @click="storageExitPay" -->
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { Lock, QuestionFilled } from '@element-plus/icons-vue'
</script>

<script>
import { mapActions, mapGetters } from 'vuex';
import { filter, findIndex, find } from 'lodash';
import moment from 'moment';
import { ElLoading } from 'element-plus'

export default {
  components: {},
  data() {
    return {
      search: '',
      bottlesChecked: [],
      drawerExitBottleStorage: false,
      isLoading: false,
      paymentMode: 'CB_VISA_MASTERCARD',
      paymentFeesPercent: null,
      insurranceStorageSelected: false,

      card: {
        fullnames: '',
        card_number: '',
        card_expiration_date: '',
        card_cvx: '',
        card_type: 'visa_mastercard',
      },
      cardRegex: {
        fullnames: false,
        card_number: false,
        card_expiration_date: false,
        card_cvx: false,
      }
    }
  },
  async mounted() {
    await this.storageBottles();
    await this.getClient();
  },
  watch: {
    drawerExitBottleStorage() {
      if (this.drawerExitBottleStorage) {
        const payload = {
          bottles: this.bottlesChecked
        }
        this.pickingRating(payload);
      }
    },
    'card.card_expiration_date': function () {
      if (this.card.card_expiration_date) {
        let slashCount = 0
        for (var i = 0; i < this.card.card_expiration_date.length; i++) {
          if (this.card.card_expiration_date[i] === '/') {
            slashCount++
          }
        }

        if (slashCount > 1) {
          this.card.card_expiration_date = ''
        }

        if (this.card.card_expiration_date.length === 2) {
          this.card.card_expiration_date += '/'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getStorageBottles: 'getStorageBottles',
      getUserDetails: 'getUserDetails',
      getRatings: 'getRatings'
    }),
    drawerSize() {
      if (window.innerWidth < 500) {
        return '100%';
      }
      return '50%';
    },
    visiblestorageFiltered() {
      return filter(this.getStorageBottles, (o) => {                   
        return (o.ticker.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
          o.title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
          String(o.store_start).indexOf(this.search) !== -1 ||
          String(o.isbn).indexOf(this.search) !== -1) ||
          String(o.spirit_type_label.toLowerCase()).indexOf(this.search.toLowerCase()) !== -1 ||
          String(o.pseudo).indexOf(this.search) !== -1
      });
    },
    mergeExitBottles() {
      const mergeBottles = [];
      const isbnIncludes = [];

      this.bottlesChecked.forEach((checked) => {

        if (!isbnIncludes.includes(checked.isbn)) {
          mergeBottles.push(checked);
          isbnIncludes.push(checked.isbn);
        }
      })

      console.log(mergeBottles);
      
      return mergeBottles;
    }
  },
  methods: {
    ...mapActions({
      storageBottles: 'storageBottles',
      getClient: 'getAccountDetails',
      getDownloadInvoice: 'downloadInvoice',
      pickingPayment: 'pickingPayment',
      pickingRating: 'pickingRating'
    }),
    dateConvert(date) {
      const returnDate = moment(date).format('DD/MM/YYYY HH:mm');

      if (returnDate !== 'Invalid date') {
        return returnDate;
      }

      return '-';
    },
    downloadInvoice(row) {
      this.getDownloadInvoice(row.invoice_uuid);
    },
    checkBottle(row) {
      const findIndexBottle = findIndex(this.bottlesChecked, (b) => {
        return b.storage_uuid === row.storage_uuid;
      });

      if (findIndexBottle !== -1) {
        this.bottlesChecked.splice(findIndexBottle, 1);
      } else {
        this.bottlesChecked.push(row);
      }
    },
    getClientAddress() {      
      const findAddress = find(this.getUserDetails.address.delivery_multiple_address, (o) => {
        console.log(o);
        
        return o.is_reference === true;
      })      
      
      if (findAddress) {
        return `${findAddress.address}, ${findAddress.zip} ${findAddress.city}`;
      } else {
        return `N/A`;
      }
    },
    paymentTypeFees() {
      if (this.paymentMode === 'CB_VISA_MASTERCARD') {

        this.paymentFeesPercent = 2;

        if (this.insurranceStorageSelected) {
          return this.getRatings.payment_type_fees_inssurrance.visa_mastercard;
        } else {
          return this.getRatings.payment_type_fees.visa_mastercard;
        }

      } else if (this.paymentMode === 'AMEX') {

        this.paymentFeesPercent = 3;

        if (this.insurranceStorageSelected) {
          return this.getRatings.payment_type_fees_inssurrance.AMEX;
        } else {
          return this.getRatings.payment_type_fees.AMEX;
        }

      } else if (this.paymentMode === 'paypal') {

        this.paymentFeesPercent = 3.5;
        
        if (this.insurranceStorageSelected) {
          return this.getRatings.payment_type_fees_inssurrance.paypal;
        } else {
          return this.getRatings.payment_type_fees.paypal;
        }

      } else if (this.paymentMode === 'wallet') {
        
        this.paymentFeesPercent = 0;
        return 0;

      }

      return 'N/A';
    },
    getTotalExitPriceStorage() {
      if (this.insurranceStorageSelected) {

        return Number(parseFloat(this.getRatings.total + this.paymentTypeFees() + this.getRatings.insurrance_total).toFixed(2));
        
      } else {

        return Number(parseFloat(this.getRatings.total + this.paymentTypeFees()).toFixed(2));

      }
    },
    async storageExitPay() {
      if (this.paymentMode === 'CB_VISA_MASTERCARD' || this.paymentMode === 'AMEX') {

        // const regex_card_number = /^(4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13})$/
        // const regex_card_fullnames = /^([a-zA-ZÀ-ÖØ-öø-ÿ'’-]+(?:\s+[a-zA-ZÀ-ÖØ-öø-ÿ'’-]+)*)$/
        // const regex_card_expiration = /^(0[1-9]|1[0-2])\/([0-9]{2})$/
        // const regex_card_cvx = /^[0-9]{3,4}$/

        const payload = {
          card: {
            fullnames: this.card.fullnames,
            card_number: this.card.card_number,
            card_expiration_date: this.card.card_expiration_date,
            card_cvx: this.card.card_cvx,
            card_type: this.card.card_type,
          },
          bottlesList: this.bottlesChecked,
          previewTotal: Number(parseFloat(this.getRatings.total + this.paymentTypeFees()).toFixed(2)),
          paymentMode: this.paymentMode,
          insurranceStorageSelected: this.insurranceStorageSelected
        }

        payload.browserInfos = {
          AcceptHeader: 'text/html, application/xhtml+xml, application/xml;q=0.9, /;q=0.8',
          JavaEnabled: navigator.javaEnabled(),
          Language: navigator.language,
          ColorDepth: window.screen.colorDepth,
          ScreenHeight: window.screen.height,
          ScreenWidth: window.screen.width,
          TimeZoneOffset: new Date().getTimezoneOffset(),
          UserAgent: navigator.userAgent,
          JavascriptEnabled: true,
        }

        const loading = ElLoading.service({
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })

        await this.pickingPayment(payload).then(() => {
          this.$router.push('/auth/transactions/history')
          loading.close();
        })
        .catch(() => {
          loading.close();
        })

      } else if (this.paymentMode === 'wallet') {

        this.cardRegex = {
          fullnames: false,
          card_number: false,
          card_expiration_date: false,
          card_cvx: false,
        }

        const payload = {
          wallet: true,
          bottlesList: this.bottlesChecked,
          previewTotal: Number(parseFloat(this.getRatings.total + this.paymentTypeFees()).toFixed(2)),
          paymentMode: this.paymentMode,
          insurranceStorageSelected: this.insurranceStorageSelected
        }

        const loading = ElLoading.service({
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })

        await this.pickingPayment(payload).then(() => {
          this.$router.push('/auth/transactions/history')
          loading.close();
        })
        .catch(() => {
          loading.close();
        })

      } else if (this.paymentMode === 'paypal') {

        this.cardRegex = {
          fullnames: false,
          card_number: false,
          card_expiration_date: false,
          card_cvx: false,
        }

        const payload = {
          paypal: true,
          bottlesList: this.bottlesChecked,
          previewTotal: Number(parseFloat(this.getRatings.total + this.paymentTypeFees()).toFixed(2)),
          paymentMode: this.paymentMode,
          insurranceStorageSelected: this.insurranceStorageSelected
        }

        const loading = ElLoading.service({
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })

        await this.pickingPayment(payload).then(() => {
          this.$router.push('/auth/transactions/history');
          loading.close();
        })
        .catch(() => {
          loading.close();
        })

      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.dashboard-storage {
  margin: 30px;
  margin-top: 20px;

  .storage-options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 30px;
      display: flex;
  }

  h1 {
    display: flex;

    .review-page-header-info {
      display: flex;
      align-items: center;
      margin-left: 10px;

      h4 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        margin-left: 3px;
      }
    }
  }
}

.bottles-list-exit, .storage-exit-process-payment {
  margin-top: 10px;
  padding-left: 17px;
  padding-right: 17px;
}

.storage-exit-process-payment {
  display: flex;
  justify-content: center;
}

.bottle-exit-line, .bottle-exit-line-insurrance {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 17px;
  padding-right: 17px;
}

.bottle-exit-line-insurrance {
  margin-bottom: 0px;
}

.orders-searchbar {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  
  
  input {
    box-sizing: border-box;
    width: 240px;
    height: 32px;
    border: 1px solid var(--border);
    background: var(--background-color-2);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 5px 10px;
    font-size: 15px;
  }
  button {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 32px;
    background: $primary-color;
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 5px 12px;
    font-size: 15px;
  }
}

.order-alert-update-icon {
  cursor: pointer;
}

.dashboard-storage {
  min-height: 52%;
  margin-top: 20px;

  h1 {
    margin-bottom: 6px;
    padding-left: 0;
  }
}

.storage {
  border-radius: 12px;
  border: 1px solid var(--border);
  background: var(--background-color-2);
  flex-direction: column;
  align-items: flex-start;
  margin: 15px 0;
  overflow: hidden;
}

.buttons-storage-column {
  display: flex;
  justify-content: left;
  gap: 20px;
}

.title,
.price {
  display: flex;
  text-align: start;
  word-break: keep-all;
  color: var(--text-color);
  font-size: 15px;
  font-weight: 500;
  border: 0;
  background: transparent;
}

h1 {
  padding-left: 30px;
}

.ticker-maitre {
  border-radius: 6px;
  border: 1px solid var(--border);
  color: var(--text-color);
  background: transparent;
  padding: 3px;
}

.cell {
  display: flex;
  justify-content: start;
}

.download-invoice {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text-color);

  img {
    width: 20px;
  }
}

.el-table tr,
.el-table th.el-table__cell {
  background-color: var(--background-color-2);
  font-size: 15px;
  font-weight: 500;
}

.el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  border-bottom: 1px solid var(--border);
}

.el-table thead {
  color: var(--text-color);
}

.el-table__body tr:hover > td.el-table__cell {
  background-color: transparent;
}

.cell {
  color: var(--text-color);
}

.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell,
.el-table--striped:hover
  .el-table__body
  tr.el-table__row--striped:hover
  td.el-table__cell {
  background: var(--background-color-11);
}

.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: var(--background-color-3);
}

.el-table__inner-wrapper::before {
  background-color: transparent;
}

.create-order-help {
  display: flex;
  align-items: center;

  img {
      margin-right: 8px;
  }
}

.review-input-title {
  margin-top: 10px;
  margin-bottom: 5px;
}

.review-update-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .update-review-btn {
    padding: 5px 8px;
    width: 100%;
    max-width: 185px;
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    border: none;
    color: var(--text-color);
    background: $primary-color;
  }
}

.action-headband {
  height: 50px;
  padding: 0 1.5rem;
  background-color: $primary-color;
  color: var(--text-color);
  border-radius: 12px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
  }

  div button {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: transparent;
    color: $light-color;
    border: none;
    margin-left: 20px;
  }
}

.contact-billing-storage {
  border-radius: 12px;
  border: 1px solid var(--border);
  background: var(--background-color);
  margin-bottom: 47px;
  margin-top: 20px;

  .contact,
  .billing {
    padding: 10px 17px;
    padding-right: 7px;

    h3 {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 600;
      min-width: 180px;
    }

    p {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
      min-width: 400px;
    }

    button {
      background: transparent;
      border: 0;
      color: var(--light-blue);
      font-size: 15px;
      font-weight: 600;
      margin-left: 8px;
    }
  }

  .contact {
    border-bottom: 1px solid var(--border);
  }
}

.cart-payment-method-choice {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-left: 17px;

  .CB_VISA_MASTERCARD,
  .AMEX,
  .paypal,
  .wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid var(--border);
    border-radius: 3px;
    color: var(--text-color);
    cursor: pointer;

    img {
      margin-left: 5px;
    }

    .active-inactive {
      width: 15px;
    }

    p {
      margin-left: 5px;
    }
  }
}

.form-storage-payment {
  display: flex;
  flex-direction: column;
  padding: 0 17px;

  .form-input {
    border-radius: 5px;
    border: 1px solid var(--border);
    background: var(--background-color-2);
    margin-bottom: 7px;
  }

  div {
    display: flex;

    .form-input:nth-child(1) {
      margin-right: 5px;
    }
  }
}

.action-headband-scale-enter-active,
.action-headband-scale-leave-active {
  transition: transform 0.3s ease;
}

.action-headband-scale-enter-to,
.action-headband-scale-leave-from {
  transform: scale(1);
}

.action-headband-scale-leave-to,
.action-headband-scale-enter-from {
  transform: scale(0);
}

@media screen and (max-width: 500px) {

  .hidden-tel {
    display: none;
  }

  .orders-list {
    display: block !important;
  }

  .dashboard-storage {
    max-width: 100%;
    box-sizing: border-box;
    margin: 5px;
    margin-top: 20px;

    .storage-options {
      flex-direction: column;
    }

    h1 {
      margin-bottom: 10px !important;
      padding-left: 10px;
    }
  }

  .orders-searchbar {
    margin-left: 0;

    input {
      width: 80%;
    }

    button {
      width: 20%;
      justify-content: center;
    }
  }

  .storage {
    margin: 5px;
  }
}

.contact-billing-storage {
  margin-bottom: 25px;
  border-left: 0;
  border-right: 0;
  border-radius: 0;

  .contact,
  .billing {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    h3,
    p {
      width: auto;
      min-width: auto !important;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
    }

    button {
      max-width: 65px;
      margin-left: 0;
    }
  }
}

/* Columns */
.cell {
  display: flex;
  align-items: center;
}
</style>
